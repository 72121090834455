import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Button } from "@components/Button"
import { Arrow } from "@components/UI"
import { CareerForm } from "@components/Form"
import { splitText } from "@utils"
import employeeQuoteData from "@json/employee-quote.json"
import QuoteLeft from "@svg/ui/quote-left.svg"

const CareerTemplate = ({ data }) => {
  const { departments } = data
  const { title, career } = data.career.edges[0].node
  const relevantDepartment = data.career.edges[0].node.departments.nodes[0].slug
  const departmentIndex = departments.edges.findIndex((department) => department.node.slug === relevantDepartment)
  const { themeColor } = usePageSetup({ themeColor: "bg-pink-light", displayGeneralForm: false })
  const themeColorBefore = "before:bg-pink-light"

  return (
    <Layout>
      <Seo title="Career" description="" />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "Are you our next",
                  break: "",
                  "text-gray-darkest": `${title}?`,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main>
        <section className="relative overflow-hidden">
          <div className={`hidden container lg:grid grid-cols-12 gap-x-4 ${themeColor}`} style={{ height: "40px" }}>
            <div className={`col-start-2 col-span-6 ${themeColor} extend ${themeColorBefore}`}></div>
            <div className="col-span-5 pl-4 bg-pink extend after:bg-pink"></div>
          </div>

          <div className="container grid-cols-12 lg:grid gap-x-4">
            <div className="grid-cols-12 col-span-7 xl:col-start-2 xl:col-span-6 lg:grid">
              <div className="col-span-11 pt-clamp-38-50">
                <div
                  data-aos="fade-up"
                  className="career-description "
                  dangerouslySetInnerHTML={{ __html: career.description }}
                ></div>
              </div>
            </div>
            <div className="grid-cols-12 col-span-5 mt-16 sm:grid lg:block lg:mt-0">
              <div className="relative flex grid-cols-11 col-span-11 col-start-2 sm:grid lg:grid-cols-5">
                <div className="absolute inset-0 bg-pink extend before:bg-pink sm:before:bg-transparent after:bg-pink"></div>
                <div className="relative grid grid-cols-8 col-span-10 col-start-2 py-20 sm:grid-cols-5 sm:gap-x-2 lg:block lg:col-span-4">
                  <div data-aos="fade-left" className="col-span-8 z-1 sm:col-span-2">
                    <div className="relative z-0 overflow-hidden rounded-full w-38 h-38 lg:w-50 lg:h-50">
                      <GatsbyImage
                        className="h-full"
                        image={getImage(data[employeeQuoteData[relevantDepartment].slug])}
                        alt={employeeQuoteData[relevantDepartment].name}
                      />
                    </div>
                  </div>
                  <div className="col-span-7 col-start-2 mt-10 sm:col-span-3 lg:col-span-5 xl:col-span-4 sm:mt-0 lg:mt-8">
                    <div data-aos="fade-left">
                      <div className="flex text-white fill-current" style={{ width: "20px", height: "20px" }}>
                        <QuoteLeft />
                      </div>
                      <div className="text-white">
                        <p
                          className="mt-4"
                          dangerouslySetInnerHTML={{ __html: employeeQuoteData[relevantDepartment].quote }}
                        />
                        <h3 className="text-clamp-18-26 mt-clamp-8-11">{employeeQuoteData[relevantDepartment].name}</h3>
                        <h4 className="font-medium font-book text-clamp-14-22">
                          {employeeQuoteData[relevantDepartment].title}
                        </h4>
                      </div>
                    </div>
                    <div className="flex mt-20 sm:mt-16 lg:mt-15">
                      <div data-aos="fade-right" className="ml-auto">
                        <Button
                          link={`/about-us/?dep=${departmentIndex}#team`}
                          className="text-white sm:hover:text-gray-darkest"
                        >
                          {employeeQuoteData[relevantDepartment].label}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container grid-cols-12 lg:grid gap-x-4 mt-clamp-18-25 pb-clamp-32-50">
            <div className="grid-cols-12 col-span-8 xl:col-start-2 xl:col-span-7 lg:grid">
              <div className="col-span-11 career-main" dangerouslySetInnerHTML={{ __html: career.main }}></div>
            </div>
            <div className="col-span-4 career-additional mt-clamp-18-25 lg:mt-0">
              <h4 className="text-clamp-18-22">Location</h4>
              <div className="mt-5" dangerouslySetInnerHTML={{ __html: career.location }}></div>
              {career.compensation && (
                <div>
                  <h4 className="text-clamp-18-22 mt-clamp-10-14">Compensation</h4>
                  <div className="mt-5" dangerouslySetInnerHTML={{ __html: career.compensation }}></div>
                </div>
              )}
              <h4 className="text-clamp-18-22 mt-clamp-10-14">Equal Opportunity Employer</h4>
              <div className="mt-5" dangerouslySetInnerHTML={{ __html: career.equality }}></div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="bg-teal-light pt-clamp-33-57 pb-clamp-18-62">
          <div className="container flex lg:justify-center">
            <div className="xl:w-10/12">
              <div style={{ maxWidth: "365px" }}>
                <h2 data-aos="stagger" className="text-title">
                  <span>Apply</span> <span>now.</span>
                </h2>
              </div>
              <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                <div className="col-span-8 xl:col-span-7">
                  <p data-aos="fade-up">
                    {career.truefalseInternship
                      ? `In your cover letter, please indicate that you are applying for the “${title}” position.`
                      : `In your cover letter, please include your salary requirements and indicate that you are applying for the “${title}” position.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="mx-auto xl:w-10/12">
              <CareerForm id="273" subject={`${title} | Application`} recipient={career.recipient} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default CareerTemplate

export const careerQuery = graphql`
  query careerQuery($id: String!) {
    career: allWpCareer(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          departments {
            nodes {
              slug
            }
          }
          career {
            description
            main
            location
            compensation
            equality
            recipient
            truefalseInternship
          }
        }
      }
    }
    departments: allWpDepartment {
      edges {
        node {
          slug
        }
      }
    }
    erin: file(relativePath: { eq: "careers/quote/erin-kelley.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mike: file(relativePath: { eq: "careers/quote/mike-gerberville.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    barb: file(relativePath: { eq: "careers/quote/barbara-kaulius.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
